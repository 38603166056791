import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StyledTabsNewV2 } from './style';
import { Tab } from "react-bootstrap";
import {CategoryContainer,Layout, NewProgressCard, NewProgressContent, NewProgressPoints, ProgressBar, MainProgressBar, PointContent, ImageBar, ImageTag, BronzeImg, SilverImg, GoldImg, FirstClass, SecondClass, ThirdClass, FourClass, Bronze, Silver, Gold, Finished} from '../../containers/Rewards/newRewardDataStyles';
import { ImageUrl, imgPath } from '../../utils/constants';
import { commaSeperator, findMonth } from '../../utils/methods';
import { Waiting } from '../../components';
import { getUserRewardDetails, getCompanyRewardTierAPI } from '../../redux/actions/peopleHomeActions';
import { getRewardPointsV2 } from '../../redux/actions/wellnessDashboardActions';
import { withTranslation } from 'react-i18next';
import NewRewardCatagory from '../../containers/Rewards/NewRewardCatagory';
import _ from 'lodash';
import CompanyRewardsV2 from './CompanyRewardsV2';
import LazyImage from '../common/LazyImage/LazyImage';

class PeopleAcheivementData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 1,
      firstIndex: 0,
      secondIndex: 0,
      companyId: props?.userProfileCompany?.company_details?.id,
      authtoken: props?.setUserDetails['auth_token']
    }
  }

  setActiveTab = (active) => {
    this.setState({ selectedTab: Number(active) })
  }

  componentDidMount() {
    window.console.log("hi")
    this.setState({
      firstIndex: 10,
      secondIndex: 5,
    });
    const { getUserRewardDetails, getCompanyRewardTierAPI, getRewardPointsV2, userId, selectdUserAuth } = this.props;
    const { companyId } = this.state;
    getUserRewardDetails(userId);
    getCompanyRewardTierAPI(companyId);
    getRewardPointsV2(userId, selectdUserAuth);
  }

  render() {
    const { reward, fitnessDetails, socialDetails, wellnessDetails, educationDetails, bonusWellness, bonusSocial, bonusEducation, bonusFitness, t, companyRewardsBarValues, userId, monthlyAdminRewardPoint,companyBranding } = this.props;
    if (!reward || _.isUndefined(companyRewardsBarValues)) {
      return <Waiting />
    }
    let finishPoint = 10000;
    const bronzePoints = !_.isUndefined(companyRewardsBarValues.bronze) ? companyRewardsBarValues.bronze : 0;
    const silverPoints = !_.isUndefined(companyRewardsBarValues.silver) ? companyRewardsBarValues.silver : 0;
    const goldPoints = !_.isUndefined(companyRewardsBarValues.gold) ? companyRewardsBarValues.gold : 0;
    const bronze = bronzePoints / 10000 * 100;
    const silver = silverPoints / 10000 * 100;
    const gold = goldPoints / 10000 * 100;
    const points = reward.total;
    const categoryArray = [
      {
        src: 'Fitness_points_bg',
        title: 'Fitness Points',
        availalePoint: reward.max_fitness,
        earnPoint: reward.fitness_point,
        percentage: reward.fitness_percentage,
        maxAvailableFitnessPoint: reward.max_fitness_points || 0,
        details: fitnessDetails || [],
        bonus: bonusFitness,
      },
      {
        src: 'Social_Points_bg',
        title: 'Social Points',
        availalePoint: reward.max_social,
        earnPoint: reward.social_point,
        percentage: reward.social_percentage,
        details: socialDetails || [],
        bonus: bonusSocial,
      },
      {
        src: 'healthAssessment_points_bg',
        title: 'Health Assessment Points',
        availalePoint: reward.max_wellness,
        earnPoint: reward.wellness_point,
        percentage: reward.wellness_percentage,
        details: wellnessDetails || [],
        bonus: bonusWellness,
      },
      {
        src: 'Wellness_points_bg',
        title: 'Wellness Points',
        availalePoint: reward.max_education,
        earnPoint: reward.education_point,
        percentage: reward.education_percentage,
        details: educationDetails || [],
        bonus: bonusEducation,
      }
    ]
    const date = new Date();
    const month = findMonth(date.getMonth());
    let progressData = {};
    progressData.point = reward.total === 10000 ? finishPoint : reward.total > 10000 ? finishPoint : reward.total >= goldPoints + 1 ? 10000 - reward.total : reward.total >= silverPoints + 1 ? (goldPoints + 1) - reward.total : reward.total < bronzePoints ? bronzePoints - reward.total : (silverPoints + 1) - reward.total;
    progressData.level = reward.total > 10000 ? '' : reward.total < bronzePoints ? 'bronze' : reward.total < silverPoints + 1 ? 'silver' : reward.total < goldPoints + 1 ? 'gold' : reward.total < finishPoint ? 'Finished' : reward.total === finishPoint ? 'Finished' : '';
    return (
      <div>
        <StyledTabsNewV2 defaultActiveKey={1} id="styledTabs" onSelect={this.setActiveTab} style={{ background: '#f7f5eb', border: '0px', borderRadius: '0px' }}>
          <Tab eventKey={1} title="Points" tabClassName="first-tab">
            {this.state.selectedTab == 1 ?
              <Layout>
                <NewProgressCard >
                  <NewProgressContent>
                    <div className='main_content'>
                      {reward.total >= 1 &&
                        <div className="newImageData">
                          <ImageTag>
                            <img src={reward.total > goldPoints ? `${imgPath}/NewRewardSection/gold_bg_img.png` : reward.total > silverPoints ? `${imgPath}/NewRewardSection/slivers_bg_img.png` : reward.total > bronzePoints ? `${imgPath}/NewRewardSection/bronze_bg_img.png` : `${imgPath}/NewRewardSection/no_BadgeImg.png`} />
                          </ImageTag>
                        </div>
                      }
                      <div className='main_point_class'>
                        <div className='point_class'>
                          <div className='total_value'>{t("Total Points")}</div>
                          <div className='total_point_value'>{commaSeperator(reward.total)}</div>
                          <div className='left_point'>{reward.total <= 10000 && <div className="inline"> <span className='point'>{reward.total === 10000 ? '' : commaSeperator(progressData.point)}</span> <span className='point_reach'>{reward.total === 10000 ? '' : progressData.point > 1 ? 'points left to reach' : 'point left to reach  '}</span> <span className='level'>{progressData.level}</span></div>}</div>
                          <div className='border_line' />
                          <div className='left_point'>{monthlyAdminRewardPoint} <span className='earn_point'>points earned in</span> {month}</div>
                        </div>
                      </div>
                    </div>
                  </NewProgressContent>
                  <NewProgressPoints>
                    <MainProgressBar>
                      <ProgressBar width={points <= 10000 ? points * 100 / 10000 : 100} color={points > silverPoints ? "#DCC34C" : points > bronzePoints ? "#B9C1C6" : "#A57576"}>
                        <div className="progrss" />
                        <BronzeImg width={points > bronzePoints ? '100%' : '100%'} imageWidth={(points > bronzePoints + 1) || (points === bronzePoints) ? '1' : ''} left={points < bronzePoints ? `calc(${bronze}% - 38px)` : `calc(${bronze}% - 60px)`} top={(points === 0 ? '-27px' : '-65px') || (points < bronzePoints && points != 0 ? '-65px' : '-77px')}>
                          <Bronze paddingLeft={points < bronzePoints ? '-5px' : '5px'} paddingBottom={points < bronzePoints ? '10px' : '6px'}>Bronze</Bronze>
                          <LazyImage src={`${ImageUrl}/images/Rewards/bronze.png`} />
                          <FirstClass marginTop={points < bronzePoints ? '10px' : '5px'} paddingLeft={points < bronzePoints ? 'none' : '2px'} marginLeft={points < bronzePoints ? '-5px' : 'none'}>{commaSeperator(bronzePoints)}pts</FirstClass>
                        </BronzeImg>
                        <SilverImg width={points > silverPoints ? '100%' : '100%'} imageWidth={(points > silverPoints + 1) || (points === silverPoints) ? '1' : ''} left={points < silverPoints ? `calc(${silver}% - 35px)` : `calc(${silver}% - 60px)`} top={(points === 0 ? '-152px' : '-190px') || (points < silverPoints && points != 0 ? '-190px' : '-201px')}>
                          <Silver paddingLeft={points < silverPoints ? '2px' : '10px'} paddingBottom={points < silverPoints ? '10px' : '6px'}>Silver</Silver>
                          <LazyImage src={`${ImageUrl}/images/Rewards/silver.png`} />
                          <SecondClass marginTop={points < silverPoints ? '12px' : '8px'} paddingLeft={points < silverPoints ? 'none' : '2px'} marginLeft={points < silverPoints ? '-5px' : 'none'}>{commaSeperator(silverPoints)}pts</SecondClass>
                        </SilverImg>
                        <GoldImg width={points > goldPoints ? '100%' : '100%'} imageWidth={(points > goldPoints + 1) || (points === goldPoints) ? '1' : ''} left={points < goldPoints ? `calc(${gold}% - 35px)` : `calc(${gold}% - 60px)`} top={(points === 0 ? '-275px' : '-314px') || (points < goldPoints && points != 0 ? '-314px' : '-324px')}>
                          <Gold paddingLeft={points < goldPoints ? '2px' : '10px'} paddingBottom={points < goldPoints ? '10px' : '6px'}>Gold</Gold>
                          <LazyImage src={`${ImageUrl}/images/Rewards/gold.png`} />
                          <ThirdClass marginTop={points < goldPoints ? '10px' : '5px'} paddingLeft={points < goldPoints ? 'none' : '2px'} marginLeft={points < goldPoints ? '-5px' : 'none'}>{commaSeperator(goldPoints)}pts</ThirdClass>
                        </GoldImg>
                        <ImageBar>
                          <div className='flag_img'>
                            <Finished>Finish</Finished>
                            <LazyImage src={`${ImageUrl}/images/NewRewardSection/finish-flag.png`} />
                            <FourClass>{commaSeperator(companyRewardsBarValues.finished)}pts</FourClass>
                          </div>
                        </ImageBar>
                      </ProgressBar>
                    </MainProgressBar>
                    <PointContent />
                  </NewProgressPoints>
                </NewProgressCard>
                <CategoryContainer>
                  {categoryArray.map((category, index) => (
                    <NewRewardCatagory category={category} key={index} history={this.props.history} monthlyRewardPoint={this.props.monthlyRewardPoint} getIndex={index} />
                  ))}
                </CategoryContainer>
              </Layout>
              : " "
            }
          </Tab>
          {companyBranding?.company_branding?.company_rewards ? <Tab eventKey={2} title="Company Rewards" tabClassName="second-tab" >
            {this.state.selectedTab === 2 ?
              <CompanyRewardsV2 userId = {userId} />
              : " "
            }
          </Tab>:null}
        </StyledTabsNewV2>
      </div>
    );
  }
}

PeopleAcheivementData.propTypes = {
  reward: PropTypes.object,
  monthlyRewardPoint: PropTypes.number,
  fitnessDetails: PropTypes.array,
  socialDetails: PropTypes.array,
  wellnessDetails: PropTypes.array,
  educationDetails: PropTypes.array,
  bonusWellness: PropTypes.array,
  bonusSocial: PropTypes.array,
  getUserRewardDetails: PropTypes.func,
  history: PropTypes.object,
  bonusFitness: PropTypes.array,
  bonusEducation: PropTypes.array,
  t: PropTypes.func,
  getCompanyRewardTierAPI: PropTypes.func,
  companyRewardsBarValues: PropTypes.array,
  companyId: PropTypes.number,
  setUserDetails: PropTypes.array,
  getRewardPointsV2: PropTypes.func,
  userId: PropTypes.number,
  monthlyAdminRewardPoint: PropTypes.number,
  selectdUserAuth: PropTypes.string,
  userProfileCompany: PropTypes.array,
  companyBranding: PropTypes.object
};

const mapStateToProps = (state) => ({
  reward: state.wellnessDashboard.reward,
  monthlyRewardPoint: state.wellnessDashboard.monthlyRewardPoint,
  fitnessDetails: state.wellnessDashboard.fitnessDetails,
  socialDetails: state.wellnessDashboard.socialDetails,
  wellnessDetails: state.wellnessDashboard.wellnessDetails,
  educationDetails: state.wellnessDashboard.educationDetails,
  bonusWellness: state.wellnessDashboard.bonusWellness,
  bonusSocial: state.wellnessDashboard.bonusSocial,
  bonusFitness: state.wellnessDashboard.bonusFitness,
  bonusEducation: state.wellnessDashboard.bonusEducation,
  companyRewardsBarValues: state.wellnessDashboard.companyRewardsBarValues,
  monthlyAdminRewardPoint: state.wellnessDashboard.monthlyAdminRewardPoint,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
});

const mapDispathcToProps = (dispatch) => ({
  getUserRewardDetails: (userId) => dispatch(getUserRewardDetails(userId)),
  getCompanyRewardTierAPI: (companyId) => dispatch(getCompanyRewardTierAPI(companyId)),
  getRewardPointsV2: (userId, auth) => dispatch(getRewardPointsV2(userId, auth))
})

export default connect(mapStateToProps, mapDispathcToProps)(withTranslation()(PeopleAcheivementData));